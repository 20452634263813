
import CompTable from "@/components/comp-table.vue"

import Component, { ComponentMethods } from "@/module/component/component"

import Employee from "@/entity/employee"

class ExpensesTablePage extends ComponentMethods implements ComponentEntity {
    components = {
        CompTable,
    }

    activated() {
        this.$refs.comp_table.refresh()

        console.log(Employee.get())
    }

    onLoad() {
        console.log("onLoad")
    }

    data() {
        return {
            columns: [
                {
                    title: "支出类型",
                    render: (h: Function, params: obj) => {
                        return (
                            { PROMOTION_COMMISSION: "推广佣金", MARKETING_EXPENSE: "营销费用", OFFICE_EXPENSE: "办公支出", OTHER_EXPENSE: "其他支出", OTHER_INCOME: "其他收入" }[
                                params.row.incExpType
                            ] || "其他"
                        )
                    },
                },
                {
                    title: "支出金额",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.amount ? "0.00" : (params.row.amount / 100).toFixed(2))
                    },
                },
                {
                    title: "车辆（推广佣金）",
                    key: "vehicle.vehiclePlateNum",
                },
                {
                    title: "描述",
                    key: "description",
                },
                {
                    title: "创建日期",
                    key: "createdDate",
                },
            ],
        }
    }
}

export default Component.build(new ExpensesTablePage())
