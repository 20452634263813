import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "table-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Option = _resolveComponent("Option")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_comp_table = _resolveComponent("comp-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_comp_table, {
      ref: "comp_table",
      title: "支出管理",
      entity: "IncomeExpense",
      columns: _ctx.columns,
      "table-api": "WEB://IncomeExpense/FindAllToPage?type=EXPENSE"
    }, {
      search: _withCtx((evt) => [
        _createVNode(_component_Select, {
          modelValue: evt.search.type,
          "onUpdate:modelValue": ($event: any) => ((evt.search.type) = $event),
          placeholder: "支出类型",
          style: {"width":"200px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([
                            { name: '推广佣金', value: 'PROMOTION_COMMISSION' },
                            { name: '营销费用', value: 'MARKETING_EXPENSE' },
                            { name: '办公支出', value: 'OFFICE_EXPENSE' },
                            { name: '其他支出', value: 'OTHER_EXPENSE' },
                            { name: '其他收入', value: 'OTHER_INCOME' },
                        ], (item) => {
              return _createVNode(_component_Option, {
                value: item.value,
                key: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_Input, {
          modelValue: evt.search.description,
          "onUpdate:modelValue": ($event: any) => ((evt.search.description) = $event),
          placeholder: "描述"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      operate: _withCtx(() => [
        _createVNode(_component_Button, {
          type: "info",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/expenses/form')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("新增一笔支出")
          ]),
          _: 1
        })
      ]),
      "row-operate": _withCtx((params) => [
        _createVNode(_component_Button, {
          type: "info",
          style: {"margin":"3px"},
          onClick: ($event: any) => (_ctx.$router.push('/expenses/form?id=' + params.row.uuid))
        }, {
          default: _withCtx(() => [
            _createTextVNode("编辑")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_Button, {
          type: "error",
          style: {"margin":"3px"},
          onClick: ($event: any) => (params.event.delete({ uuid: params.row.uuid }))
        }, {
          default: _withCtx(() => [
            _createTextVNode("删除")
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["columns"])
  ]))
}